<template>
  <Page
    :title="$vuetify.breakpoint.mobile ? 'Links' : 'Linksammlung'"
    color="error"
  >
    <template slot="extension">
      <v-tabs background-color="error darken-1">
        <v-toolbar color="transparent" flat dense>
          <v-spacer />
          <v-text-field
            single-line
            dense
            hide-details
            label="Suche"
            v-model="search"
            append-icon="mdi-magnify"
            clearable
          ></v-text-field>
        </v-toolbar>
      </v-tabs>
    </template>
    <v-alert
      v-if="!search"
      type="info"
      text
      :prominent="!$vuetify.breakpoint.xs"
    >
      Die Linksammlung umfasst {{ filteredItems.length }} Einträge.
    </v-alert>
    <v-alert
      v-if="search && filteredItems.length == 0"
      type="error"
      text
      :prominent="!$vuetify.breakpoint.xs"
    >
      Die Suche nach «{{ search }}» ergab keinen Treffer!
    </v-alert>
    <v-alert
      v-if="search && filteredItems.length > 0"
      type="success"
      text
      :prominent="!$vuetify.breakpoint.xs"
    >
      {{ filteredItems.length }} Suchergebnis{{
        filteredItems.length > 1 ? 'se' : ''
      }}
      für «{{ search }}».
    </v-alert>

    <v-row>
      <v-col
        v-for="(item, index) in filteredItems"
        :key="index"
        xl="2"
        lg="3"
        md="4"
        sm="6"
        cols="12"
        align-stretch
      >
        <v-card class="d-flex flex-column fill-height">
          <v-img
            :src="require('@/assets/links/' + item.image)"
            :alt="item.title"
            class="mx-auto my-2"
            max-height="120"
            contain
          />

          <v-card-text v-html="item.description" class="my-auto"></v-card-text>
          <v-spacer />
          <v-divider />
          <v-card-actions>
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  color="primary"
                  :href="item.link"
                  target="_blank"
                  block
                  text
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon left>{{ icon(item) }}</v-icon>
                  öffnen</v-btn
                >
              </template>
              <span>{{ tooltip(item) }}</span>
            </v-tooltip>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </Page>
</template>

<script>
import Links from './links';

export default {
  data() {
    return {
      items: [],
      search: '',
    };
  },
  computed: {
    filteredItems() {
      return this.items.filter(
        (item) =>
          item.title.toLowerCase().includes(this.search.toLowerCase()) ||
          item.description.toLowerCase().includes(this.search.toLowerCase())
      );
    },
  },
  methods: {
    icon(item) {
      if (item.code) {
        return 'mdi-key';
      }
      if (item.ip) {
        return 'mdi-lock';
      }
      return 'mdi-lock-open';
    },
    tooltip(item) {
      if (item.code) {
        return 'Persönlicher Code erforderlich';
      }
      if (item.ip) {
        return 'Zugang nur aus dem Netz des Gymnasiums';
      }
      return 'Zugang frei';
    },
  },

  async created() {
    this.items = Links;
  },
};
</script>
